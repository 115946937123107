import { geojson } from 'gridtools/types';
import { GOObjects, MapSelectedProperties, MapSelectedState } from 'types';

// actions
const SELECT = 'store/map/selected/select';
const UNSELECT = 'store/map/selected/unselect';

// action types
type Properties = MapSelectedProperties<keyof GOObjects>;

export type SelectAction = { type: typeof SELECT; selected: geojson.Feature<Properties>; };
export type UnselectAction = { type: typeof UNSELECT; };

export type MapSelectedAction =
  | SelectAction
  | UnselectAction;

// action creators
export function select(selected: geojson.Feature<Properties>): SelectAction {
  const type = SELECT;
  return { selected, type };
}

export function unselect(): UnselectAction {
  const type = UNSELECT;
  return { type };
}

// reducers
function _select(state: MapSelectedState, action: SelectAction): MapSelectedState {
  return { ...action.selected };
}

function _unselect(state: MapSelectedState, action: UnselectAction): MapSelectedState {
  return null;
}

export default function mapSelectedReducer(state: MapSelectedState, action: MapSelectedAction): MapSelectedState {
  switch (action.type) {
    case SELECT: return _select(state, action);
    case UNSELECT: return _unselect(state, action);
    default: return state;
  }
}
