import { StoreState } from 'types';

import { ActionsAction, actionsReducer } from './actions';
import authReducer, { AuthAction } from './auth';
import { DroneAction, droneReducer } from './drone';
import goReducer, { GOAction } from './go';
import loadingReducer, { LoadingAction } from './loading';
import mapReducer, { MapAction } from './map';
import { MessagesAction, messagesReducer } from './messages';
import { PrintAction, printReducer } from './print';
import searchReducer, { SearchAction } from './search';

export type Action =
  | ActionsAction
  | AuthAction
  | DroneAction
  | GOAction
  | LoadingAction
  | MapAction
  | MessagesAction
  | PrintAction
  | SearchAction;

export default function rootReducer(state: StoreState, action: Action): StoreState {
  return {
    actions: actionsReducer(state.actions, action as ActionsAction),
    auth: authReducer(state.auth, action as AuthAction),
    drone: droneReducer(state.drone, action as DroneAction),
    go: goReducer(state.go, action as GOAction),
    loading: loadingReducer(state.loading, action as LoadingAction),
    map: mapReducer(state.map, action as MapAction),
    messages: messagesReducer(state.messages, action as MessagesAction),
    print: printReducer(state.print, action as PrintAction),
    search: searchReducer(state.search, action as SearchAction),
  };
}
