import { GOChildren, GOParents } from 'types/go';
import { AddressedObject, FiberCableLine, FiberCableObject } from './types';
import { DK_ACCESS_ADDRESS, TELCO } from './constants';
import { LineString, Point } from 'gridtools/types/geojson';

export const findInHierarchy = (obj: FiberCableObject, type: string,
                                checkDetails = true): FiberCableObject | undefined => {
  if (obj.type === type && (!checkDetails || hasDetails(obj)))
    return obj;

  for(const o of obj.located_in || []) {
    const result = findInHierarchy(o, type, checkDetails);
    if (result)
      return result;
  }
};

const hasDetails = (obj: FiberCableObject | GOChildren['telco_container']['root']) =>
  obj.details.type_source != null ||
  obj.details.specification != null ||
  obj.details.identification != null;

const getAddr = (obj: AddressedObject) => {
  const addr = obj.has && obj.has.find(h => h.type === DK_ACCESS_ADDRESS);
  return addr && addr.details && {
    address: addr.details.address_name,
    id: addr.id_source
  };
};

export const isRouteNode = (obj: FiberCableObject) => obj.type === TELCO.ROUTE_NODE;

type ObjInfo = {
  label?: string, spec?: string, addr?: string, location?: Point,
  objects: FiberCableObject[];

  addressId?: string;

  // case for route_node containing a container: treat it as a passthrough object [render box, no merge routes]
  isContainerInRouteNode?: boolean;
};
export const getInfo = (obj: FiberCableObject): ObjInfo | null => {

  const toInfo = (o?: FiberCableObject): ObjInfo | null => {
    if (!o || !o.details) return null;

    const details = o && o.details;

    const addrObj = o.type === TELCO.CONDUIT_ADAPTER
      ? findInHierarchy(o, TELCO.ROUTE_NODE, false)
      : o;

    const objects = [obj];
    if (o !== obj)
      objects.push(o);
    if (addrObj && addrObj !== o && addrObj !== obj)
      objects.push(addrObj);

    const address = addrObj && getAddr(addrObj);

    return {
      label: `${details.type_source}: ${details.identification || ''}`,
      spec: details.specification || '',
      addr: address && address.address,
      location: details.location,
      objects,

      addressId: address && address.id
    }
  };

  if (obj.type === TELCO.FIBER_CABLE_NODE) {
    // find container
    const info = toInfo(findInHierarchy(obj, TELCO.CONTAINER));
    if (!info) {
      return null;
    }

    const { spec, ...rest } = info;
    return rest;
  }

  if (obj.type === TELCO.ROUTE_NODE && obj.containers) {
    const childContainer = obj.containers[0];
    const info = childContainer && toInfo(childContainer);
    if (info) {
      return {
        ...info,
        isContainerInRouteNode: true
      };
    }
  }

  if (obj.type === TELCO.CONDUIT_NODE || obj.type === TELCO.ROUTE_NODE) {
    return toInfo(hasDetails(obj) && obj
      || findInHierarchy(obj, TELCO.CONTAINER)
      || findInHierarchy(obj, TELCO.CONDUIT_ADAPTER)
      || findInHierarchy(obj, TELCO.ROUTE_NODE));
  }

  if (obj.type === TELCO.CONTAINER) {
    return toInfo(obj);
  }

  return null;
};

export type _LineInfo = {
  length: number;
  above?: string;
  below: string;
  isRoute: boolean;
  centerline: (LineString | undefined)[];
  op_status: string | null;

  objects: FiberCableLine[];
}

type _Child<T extends keyof GOChildren = keyof GOChildren> = GOChildren[T]['root'];
type _Parent<T extends keyof GOParents = keyof GOParents> = GOParents[T]['root'];
const findInChildren =
  <Type extends keyof GOChildren>(type: Type, contains?: _Child[]) =>
  (contains || []).find(c => c.type === type) as _Child<Type> | undefined ;
const findInParents =
  <Type extends keyof GOParents>(type: Type, located_in?: _Parent[]) =>
  (located_in || []).find(l => l.type === type) as _Parent<Type> | undefined;

export const getLineInfo = (line: FiberCableLine): _LineInfo => {

  const length = line.details.geographical_length_m || 0;
  const childConduit = findInChildren('telco_conduit', line.contains);

  const getParts = (cnd?: _Child<'telco_conduit'>) => {
    if (!cnd)
      return [];
    return [
      cnd.details.identification,
      cnd.details.specification
    ].filter(s => s);
  };

  const isRoute = line.type === TELCO.ROUTE;
  return {
    length,
    isRoute,
    above: !isRoute && getParts(childConduit)[0] || '',
    below: getParts(line.type === 'telco_conduit' ? line : childConduit).join(', '),
    op_status: isRoute ? line.details.op_status : null,

    centerline: [line.details.centerline || undefined],

    objects: childConduit ? [childConduit, line] : [line]
  };
};

export const findConduitContainer = (cnd: FiberCableLine) => {
  return cnd.type === TELCO.CONDUIT
    && findInParents('telco_container', cnd.located_in);
};
