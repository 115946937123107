import { go } from 'gridtools/types/index';
import { telco } from 'gridtools/go/index';
import { mapSort } from 'app/utils/lists';
import { NodeDetails, NodeDetailsType } from './types';

const fetchResult = <T>(req: go.request.Request<T, any>) => {
  return req.request.then(d => d.type === 'success'
    ? Promise.resolve(d.result)
    : Promise.reject(d));
};

export class ApiHelper {
  private readonly addressRequests: { [key: string]: Promise<string | undefined> } = {};
  private readonly pendingRequests: Promise<any>[] = [];

  constructor(token: string, url = API_URLS.gridoptimizer,
              private readonly parentStruct = telco.optical_path.parentStructure(url, token),
              private readonly containerHas = telco.container.has(url, token),
              private readonly routeNodeHas = telco.route_node.has(url, token)) {
  }

  fetchParentStructure = (id: string) =>
    fetchResult(this.parentStruct({ id, details: 'full' }));

  startFetchingAddress(details?: NodeDetails | null) {
    if (!details)
      return;

    const { id_source: id, type } = details;
    if (!id || !type)
      return;

    const key = [type, id].join();
    const request = this.addressRequests[key]
      || (this.addressRequests[key] = this.getAddress(id, type));

    this.pendingRequests.push(
      request.then(addr => details.address = addr));
  };

  getPendingRequests = () => Promise.all(this.pendingRequests);

  private async getAddress(id: string, type: NodeDetailsType) {

    const method = type === 'telco_container'
      ? this.containerHas
      : this.routeNodeHas;
    const addr = await fetchResult(method({ details: 'full', id }));

    const address = addr.dk_access_address && addr.dk_access_address[0];
    return address && address.details.address_name;
  };
}

export class IdentificationBuilder {
  private readonly groups: { [sequence: number]: string[] } = {};

  append(sequence: number, value: string | null) {
    if (!value) return;

    const array = this.groups[sequence] || (this.groups[sequence] = []);
    array.push(value);
  }

  build(sequence: number): string {
    const array = this.groups[sequence];
    if (!array) return '';

    const sorted = mapSort(array, s => s);
    const unique = sorted.filter((s, i) => s !== sorted[i - 1]);
    return unique.join(',');
  }
}
