import { Language } from 'types';

class AnalyticsTracker {
  private _debug: boolean;
  private _enabled: boolean;

  constructor() {
    this._debug = FEATURES.analytics.debug;
    this._enabled = FEATURES.analytics.enabled;
    const id = FEATURES.analytics.tracking_id;
    if (this._debug) {
      console.log(`ga('create', '${id}', 'auto', { siteSpeedSampleRate: 100 });`);
    }
    ga('create', id, 'auto', { siteSpeedSampleRate: 100 });
    if (!this._enabled) {
      ga('set', 'sendHitTask', null);
    }
  }

  pageView(page: string, title: string) {
    if (this._debug) {
      console.log(`ga('set', 'page', '${page}')`);
      console.log(`ga('set', 'title', '${title}')`);
      console.log(`ga('send', 'pageview')`);
    }
    ga('set', 'page', page);
    ga('set', 'title', title);
    ga('send', 'pageview');
  }

  setLanguage(language: Language) {
    let value: string;
    switch (language) {
      case Language.Danish: value = 'Danish'; break;
      default: value = 'English'; break;
    }
    if (this._debug) {
      console.log(`ga('set', 'dimension1', '${value}');`);
    }
    ga('set', 'dimension1', value);
  }

  setUser(id: string | number) {
    if (this._debug) {
      console.log(`ga('set', 'userId', '${id}')`);
    }
    ga('set', 'userId', id);
  }
}

export const tracker = new AnalyticsTracker();
