import { MapTrace, MapTraceState } from 'types';

const REMOVE = 'store/map/trace/remove';
const SET_TRACE = 'store/map/trace/set-trace';

export function remove() {
  return { type: REMOVE as typeof REMOVE };
}

export function setTrace(trace: MapTrace) {
  return { trace, type: SET_TRACE as typeof SET_TRACE };
}

export type RemoveAction = ReturnType<typeof remove>;
export type SetTraceAction = ReturnType<typeof setTrace>;

export type MapTraceAction =
  | RemoveAction
  | SetTraceAction;

function _remove(state: MapTraceState, action: RemoveAction): MapTraceState {
  return null;
}

function _setTrace(state: MapTraceState, action: SetTraceAction): MapTraceState {
  return action.trace;
}

export default function traceReducer(state: MapTraceState, action: MapTraceAction): MapTraceState {
  switch (action.type) {
    case REMOVE: return _remove(state, action);
    case SET_TRACE: return _setTrace(state, action);
    default: return state;
  }
}
