import { TEXT_SEARCH_SCOPE } from 'app/constants';
import { setResults } from 'app/reducers/search';
import { search as searchAPI } from 'gridtools/go/telco';
import { go, utils } from 'gridtools/types';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { StoreState } from 'types';

const SEARCH = 'sagas/search/search';

type SearchCallback = (result: go.request.Response<go.telco.search.Search[], go.error.ObjectError>) => void;
type SearchResponse = utils.UnwrapPromise<ReturnType<ReturnType<typeof searchAPI>>['request']>;

export type SearchAction = {
  callback?: SearchCallback;
  query: string | null;
  type: typeof SEARCH;
};

export type SearchActions =
  | SearchAction;

export function search(query: string | null, callback?: SearchCallback): SearchAction {
  const type = SEARCH;
  return { callback, query, type };
}

function* searchSaga(action: SearchActions) {
  const token = yield select((state: StoreState) => state.auth.user === null ? null : state.auth.user.token);
  if (token !== null) {
    if (action.query === null) {
      yield put(setResults(null));
    } else {
      try {
        const boundSearch = searchAPI(API_URLS.gridoptimizer, token);
        const opts: go.telco.search.SearchOptions = { query: action.query, scope: TEXT_SEARCH_SCOPE };
        const getResponse = () => boundSearch(opts).request;
        const response: SearchResponse = yield call(getResponse);
        if (response.type === 'success') {
          const results = response.result;
          yield put(setResults(results));
        }
        if (action.callback !== undefined) {
          action.callback(response);
        }
      } catch {
        // do nothing
      }
    }
  }
}

export default function* mapSaga() {
  yield takeLatest(SEARCH, searchSaga);
}
