import { MapState } from 'types';

import { layersReducer, MapLayersAction } from './layers';
import { MapMeasurementAction, measurementReducer } from './measurement';
import searchReducer, { MapSearchAction } from './search';
import selectedReducer, { MapSelectedAction } from './selected';
import traceReducer, { MapTraceAction } from './trace';

export type MapAction =
  | MapLayersAction
  | MapMeasurementAction
  | MapSearchAction
  | MapSelectedAction
  | MapTraceAction;

export default function mapReducer(state: MapState, action: MapAction): MapState {
  return {
    layers: layersReducer(state.layers, action as MapLayersAction),
    measurement: measurementReducer(state.measurement, action as MapMeasurementAction),
    search: searchReducer(state.search, action as MapSearchAction),
    selected: selectedReducer(state.selected, action as MapSelectedAction),
    trace: traceReducer(state.trace, action as MapTraceAction),
  };
}
