import * as React from 'react';
import { DELTA_Y, INIT_X, INIT_Y, ITEM_RADIUS as radius, LINE_MARGIN, STYLES, SVG_LINE, SVG_SHAPE } from './constants';
import { DataItem } from './types';

type ReportDataProps = {
  items: DataItem[];

  reportWidth: number;

  labelFontSize?: keyof typeof STYLES.text;
};

const getMiddleLinePosition = (y: number) => y + DELTA_Y.top;

type Coords = { x: number, y: number };

class CoordHelper {
  private readonly positions: number[];

  readonly xmin: number;
  readonly xmax: number;
  readonly rowSize: number;

  constructor(private readonly width: number) {

    this.positions = [
      INIT_X,
      width / 2,
      width - INIT_X
    ];

    this.xmin = LINE_MARGIN;
    this.xmax = width - LINE_MARGIN;

    this.rowSize = this.positions.length;
  }

  getCoords(i: number): Coords {
    const rowSize = this.positions.length;
    const dy = DELTA_Y.top + DELTA_Y.down;

    return {
      x: this.positions[i % rowSize],
      y: INIT_Y + Math.floor(i / rowSize) * dy
    };
  }

  getLinkTextCoords(i: number): Coords {
    const a = this.getCoords(i),
      b = this.getCoords(i + 1);

    return {
      x: (a.x + b.x) / 2,
      y: a.y === b.y
        ? a.y
        : getMiddleLinePosition(a.y)
    };
  }
}

class ReportData extends React.PureComponent<ReportDataProps> {
  private readonly coords: CoordHelper;

  constructor(props: ReportDataProps) {
    super(props);

    this.coords = new CoordHelper(props.reportWidth);
  }

  render() {
    return (
      <React.Fragment>
        {this.props.items.map((item, i) => {
          const position = this.coords.getCoords(i);
          const self = ReportData;

          const link = i < this.props.items.length - 1
            ? this.renderLink(item, position, i)
            : null;

          return [
            link,
            self.renderItem(item, position, 'shape-' + i),
            this.renderText(item, position, i)
          ];
        })}
      </React.Fragment>
    );
  }

  private renderLink(item: DataItem, curr: Coords, index: number) {
    const next = this.coords.getCoords(index + 1);

    const points = [
      [curr.x, curr.y].join(),
      [next.x, next.y].join()];

    const middle = curr.y === next.y ? null : getMiddleLinePosition(curr.y);
    if (middle) {
      const { xmin, xmax } = this.coords;
      points.splice(1, 0,
        [xmax, curr.y].join(),
        [xmax, middle].join(),
        [xmin, middle].join(),
        [xmin, next.y].join());
    }

    return (
      <polyline key={'link-' + index}
                points={points.join(' ')}
                strokeDasharray={item.style === 'dashed' ? '5 10' : undefined}
                data-middle={middle}
                {...SVG_LINE}
                strokeWidth={1} />
    );
  }

  private renderText(item: DataItem, { x, y }: Coords, index: number) {

    const { labelFontSize = 'normal' } = this.props;
    const textProps = {
      x,
      textAnchor: 'middle',
      style: STYLES.text[labelFontSize]
    };
    const key = () => `txt${index}-${texts.length}`;
    const lineHeight = textProps.style.fontSize * 1.3;

    const texts: JSX.Element[] = [];
    const appendText = (text: string | undefined, baseline: number) => {
      if (text) {
        texts.push(<text
          {...textProps}
          y={baseline}
          key={key()}>
          {text}
        </text>);
      }
    };

    item.label
      .filter(s => s)
      .forEach((text, row) => {
        const position = y + radius + (row + 1) * lineHeight;
        appendText(text, position);
      });

    if (item.type === 'passthrough') {
      const baseline = y - radius / 2 - lineHeight / 2;

      appendText(item.below && item.above, baseline - lineHeight);
      appendText(item.below || item.above, baseline);

    } else {
      const textPos = this.coords.getLinkTextCoords(index);
      textProps.x = textPos.x;

      appendText(item.above, textPos.y - lineHeight / 2);
      appendText(item.below, textPos.y + lineHeight);
    }

    return texts;
  }

  private static renderItem(item: DataItem, { x, y }: Coords, key: string) {

    const shapeAttrs = {
      ...SVG_SHAPE,
      key
    };

    return item.type === 'passthrough' || item.type === 'rn_container'
      ? <rect x={x - radius}
              y={y - radius / 2}
              width={2 * radius}
              height={radius}
              {...shapeAttrs} />
      : <circle cx={x} cy={y} r={radius}
                {...shapeAttrs} />;
  }
}

export default ReportData;
