import * as React from 'react';
import { render } from 'react-dom';
import { _Dimensions, ReportOptions, ReportProps, ReportUrlParams } from './types';
import { RouteComponentProps } from 'react-router';
import { buildFileName } from './utils';
import { createPdf } from './pdf';

type LoadState<T> = { loading: boolean, error?: any, data?: T };

export const pdfExporter = (type: string, version: string | null) => {
  const svgRef = React.createRef<SVGSVGElement>();
  const size: _Dimensions = { width: 0, height: 0, footerHeight: 0 };
  const onMeasured: ReportProps['onMeasured'] =
    d => Object.assign(size, d);

  return {
    reportProps: {
      svgRef,
      onMeasured
    },
    save() {
      const filename = buildFileName(type, version);
      return createPdf(svgRef.current as any, size)
        .then(pdf => pdf.save(filename));
    }
  }
};

export const createReportOptions = (urlParams: ReportUrlParams): ReportOptions => {

  return {
    api: API_URLS.gridoptimizer,
    //api: `https://stofa.gridoptimizer.com`,

    customer: FEATURES.organization,

    ...urlParams
  };
};

export const withReportParams = () => {

  function wrap(WrappedComponent: React.ComponentType<ReportUrlParams>) {

    class WithReportParams extends React.Component<RouteComponentProps<{ id: string }>> {
      static displayName = `WithReportParams(${WrappedComponent.name})`;

      render() {
        const { match, location } = this.props;
        const params = new URLSearchParams(location.search);
        return <WrappedComponent id={match.params.id}
                                 token={params.get('token') as string}
                                 user={params.get('user') || 'root'}
                                 version={params.get('version')} />;
      }
    }

    return WithReportParams;
  }

  return wrap;
};

abstract class ReportLoaderBase<T> extends React.Component<ReportUrlParams, LoadState<T>> {

  protected readonly options: ReportOptions;
  protected readonly pdf: ReturnType<typeof pdfExporter>;

  protected constructor(props: ReportUrlParams, title: string) {
    super(props);
    this.state = { loading: false };

    this.options = createReportOptions(props);
    this.pdf = pdfExporter(title, this.options.version);
  }

  private startLoading() {
    this.setState({ loading: true, error: null });

    this.loadData()
      .then(data => this.setState({ data }))
      .catch(error => this.setState({ error }))
      .then(() => this.setState({ loading: false }));
  }

  componentDidMount(): void {
    const { loading, data, error } = this.state;
    if (!loading && !data && !error) {
      this.startLoading();
    }
  }

  render() {
    const { state: { error, loading, data }, options } = this;
    if (loading) {
      return <p>Loading {options.id}...</p>;
    }

    if (error) {
      return <p>Error {error.status} {error.statusText}</p>;
    }

    return data ? this.renderReport(data) : null;
  }

  async createPdf(data?: T) {
    data = data || await this.loadData();

    render(
      this.renderReport(data),
      document.createElement('div'));

    await this.pdf.save();
  }

  protected abstract loadData(): Promise<T>;
  protected abstract renderReport(data: T): JSX.Element;

  protected renderPdfButton() {
    return (
      <button onClick={() => this.pdf.save()}
              style={{ marginBottom: 10 }}>Download PDF</button>
    );
  }
}

export default ReportLoaderBase;
