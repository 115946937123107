import withStyles from '@material-ui/core/styles/withStyles';

import { Loading } from './Loading';
import { _Styles } from './types';

const styles: _Styles = (theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
});

const StyledLoading = withStyles(styles)(Loading);
export default StyledLoading;
