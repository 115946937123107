export enum Action {
  DroneSelect,
  MapLayers,
  Measure,
  Print,
}

export enum Language {
  Danish = 'da',
  English = 'en',
}

export enum PropertiesFilter {
  All = 'all',
  HideEmpty = 'hide-empty',
}
