import { geojson, go } from 'gridtools/types';
import { MapSearchFailedError, MapSearchResult, MapSearchState } from 'types';

// actions
const REMOVE_RESULTS = 'store/map/search/remove-results';
const FAIL = 'store/map/search/fail';
const SET_POLYGON = 'store/map/search/set-polygon';
const SET_RESULTS = 'store/map/search/set-results';
const START_LOADING = 'store/map/search/start-loading';
const CLICK_ON_MAP = 'store/map/search/click-on-map';

// action types
export type FailAction = { type: typeof FAIL; error: MapSearchFailedError; };
export type RemoveResultsAction = { type: typeof REMOVE_RESULTS; };
export type SetPolygonAction = { type: typeof SET_POLYGON; polygon: null | geojson.Polygon; };
export type SetResultsAction = { type: typeof SET_RESULTS; results: go.telco.objects_within_polygon.ObjectsWithinPolygon; };
export type StartLoadingAction = { type: typeof START_LOADING; };
export type ClickOnMapAction = { type: typeof CLICK_ON_MAP, location: geojson.Coordinates };

export type MapSearchAction =
  | FailAction
  | RemoveResultsAction
  | SetPolygonAction
  | SetResultsAction
  | StartLoadingAction
  | ClickOnMapAction;

// action creators
export const fail = (error: MapSearchFailedError): FailAction => ({ error, type: FAIL });
export const removeResults = (): RemoveResultsAction => ({ type: REMOVE_RESULTS });
export const setPolygon = (polygon: null | geojson.Polygon): SetPolygonAction => ({ polygon, type: SET_POLYGON });
export const setResults = (results: go.telco.objects_within_polygon.ObjectsWithinPolygon): SetResultsAction => ({
  results,
  type: SET_RESULTS,
});
export const startLoading = (): StartLoadingAction => ({ type: START_LOADING });
export const clickOnMap = (location: geojson.Coordinates): ClickOnMapAction => ({ type: CLICK_ON_MAP, location });

// reducers
const _fail = (state: MapSearchState, action: FailAction): MapSearchState => ({
  ...state,
  state: { type: 'failed', error: action.error },
});

const _removeResults = (state: MapSearchState, action: RemoveResultsAction): MapSearchState => ({
  ...state,
  state: null,
});

const _setPolygon = (state: MapSearchState, action: SetPolygonAction): MapSearchState => ({
  ...state,
  polygon: action.polygon
});

const _startLoading = (state: MapSearchState, action: StartLoadingAction): MapSearchState => ({
  ...state,
  state: { type: 'loading' },
});

const _filterContainerType = (type: go.telco.container.ContainerType) => (container: go.telco.container.Container) => (
  container.details !== undefined && container.details.type === type
);

const _setResults = (state: MapSearchState, action: SetResultsAction): MapSearchState => {
  const results = { ...action.results };
  if (results.telco_container !== undefined) {
    const containers = results.telco_container;
    const rs: MapSearchResult = results;
    const hubs = containers.filter(_filterContainerType('HUB'));
    const streetCabinets = containers.filter(_filterContainerType('Street Cabinet'));
    const boxes = containers.filter(_filterContainerType('Underground Utility Box'));
    if (hubs.length !== 0) { rs.telco_container_hub = hubs; }
    if (streetCabinets.length !== 0) { rs.telco_container_street_cabinet = streetCabinets; }
    if (boxes.length !== 0) { rs.telco_container_underground_utility_box = boxes; }
    delete results.telco_container;
  }
  return {
    ...state,
    state: { result: results, type: 'success' },
  };
};

const _clickOnMap = (state: MapSearchState, action: ClickOnMapAction): MapSearchState => ({
  ...state,
  lastClickLocation: action.location
})

const mapSearchReducer = (state: MapSearchState, action: MapSearchAction): MapSearchState => {
  switch (action.type) {
    case FAIL: return _fail(state, action);
    case REMOVE_RESULTS: return _removeResults(state, action);
    case SET_POLYGON: return _setPolygon(state, action);
    case SET_RESULTS: return _setResults(state, action);
    case START_LOADING: return _startLoading(state, action);

    case CLICK_ON_MAP: return _clickOnMap(state, action);
    default: return state;
  }
};

export default mapSearchReducer;
