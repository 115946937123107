import { geojson } from 'gridtools/types';
import { MapMeasurementState } from 'types';

type AddAction = ReturnType<typeof add>;
type ResetAction = ReturnType<typeof reset>;

export type MapMeasurementAction =
  | AddAction
  | ResetAction;

const ADD = 'store/map/measurement/add';
const RESET = 'store/map/measurement/reset';

export function add(geometry: geojson.LineString, canvasFunction: ol.CanvasFunctionType) {
  const type = ADD;
  return { canvasFunction, geometry, type: type as typeof type };
}

export function reset() {
  const type = RESET;
  return { type: type as typeof type };
}

function addReducer(state: MapMeasurementState, action: AddAction): MapMeasurementState {
  return [...state, { canvasFunction: action.canvasFunction, geometry: action.geometry }];
}

function resetReducer(state: MapMeasurementState, action: ResetAction): MapMeasurementState {
  return [];
}

export function measurementReducer(state: MapMeasurementState, action: MapMeasurementAction): MapMeasurementState {
  switch (action.type) {
    case ADD: return addReducer(state, action);
    case RESET: return resetReducer(state, action);
    default: return state;
  }
}
