import { MapLayersBackgroundState, MapLayersCustomState, MapLayersGOState, MapLayersState } from 'types';

// actions
const TOGGLE_BACKGROUND = 'store/map/layers/toggle-background';
const TOGGLE_CUSTOM = 'store/map/layers/toggle-custom';
const TOGGLE_GO = 'store/map/layers/toggle-go';

export type ToggleBackgroundAction = ReturnType<typeof toggleBackground>;
export type ToggleCustomAction = ReturnType<typeof toggleCustom>;
export type ToggleGOAction = ReturnType<typeof toggleGo>;

export type MapLayersAction =
  | ToggleBackgroundAction
  | ToggleCustomAction
  | ToggleGOAction;

// action creators
export function toggleBackground(background: Partial<MapLayersBackgroundState>) {
  return {
    background,
    type: TOGGLE_BACKGROUND as typeof TOGGLE_BACKGROUND,
  };
}

export function toggleCustom(custom: MapLayersCustomState) {
  return {
    custom,
    type: TOGGLE_CUSTOM as typeof TOGGLE_CUSTOM,
  };
}

export function toggleGo(layers: Partial<MapLayersGOState>) {
  return {
    layers,
    type: TOGGLE_GO as typeof TOGGLE_GO
  };
}

// reducers
function _toggleBackground(state: MapLayersState, action: ToggleBackgroundAction): MapLayersState {
  return {
    ...state,
    background: {
      ...state.background,
      ...action.background,
    },
  };
};

function _toggleCustom(state: MapLayersState, action: ToggleCustomAction): MapLayersState {
  return {
    ...state,
    custom: {
      ...state.custom,
      ...action.custom,
    },
  };
};

function _toggleGo(state: MapLayersState, action: ToggleGOAction): MapLayersState {
  return {
    ...state,
    go: {
      ...state.go,
      ...action.layers,
    },
  };
};

export function layersReducer(state: MapLayersState, action: MapLayersAction): MapLayersState {
  switch (action.type) {
    case TOGGLE_BACKGROUND: return _toggleBackground(state, action);
    case TOGGLE_CUSTOM: return _toggleCustom(state, action);
    case TOGGLE_GO: return _toggleGo(state, action);
    default: return state;
  }
}
