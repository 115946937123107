import axios from 'axios';
import { APISAMLRequest } from 'types';

export function samlRequest() {
  const source = axios.CancelToken.source();
  const cancelToken = source.token;
  const cancel = source.cancel;
  const url = `${API_URLS.api}/saml/request`;
  const headers = {
      'content-type': 'application/json',
  };
  const body = {
      relay_state: window.location.href.replace(/\/$/, ''),
  };
  const request = axios.post<APISAMLRequest>(url, body, { cancelToken, headers });
  return { cancel, request };
}
