export const STYLES = {
  text: {
    small: { fontSize: 8 },
    normal: { fontSize: 10 },
    large: { fontSize: 14 }
  },
  svg: {
    border: '2px solid black',
    fontFamily: "'Open Sans Light', Arial, Helvetica, sans-serif"
  }
};

export const SVG_LINE = {
  strokeWidth: 2,
  stroke: 'black',
  fill: 'none',
};
export const SVG_SHAPE = {
  fill: '#92d050',
  stroke: 'black',
  strokeWidth: 1,
};

export namespace TELCO {
  export const FIBER_CABLE_NODE = 'telco_fiber_cable_node';
  export const CONTAINER = 'telco_container';
  export const ROUTE_NODE = 'telco_route_node';
  export const CONDUIT_NODE = 'telco_conduit_node';
  export const CONDUIT_ADAPTER = 'telco_conduit_adapter';

  export const ROUTE = 'telco_route';
  export const CONDUIT = 'telco_conduit';
}
export const DK_ACCESS_ADDRESS = 'dk_access_address';

const A4 = {
  format: 'a4',
  width: 297,
  height: 210
};
export const PAGE = {
  ...A4,
  margin: 5
};
export const MIN_HEIGHT_MM = PAGE.height - 2 * PAGE.margin;
export const WIDTH_MM = PAGE.width - 2 * PAGE.margin;
export const WIDTH_PX = 1122;

export const getReportHeight = (rowsCount: number,
                                footerHeight: number) => INIT_Y
  + DELTA_Y.top * rowsCount
  + DELTA_Y.down * (rowsCount - 1)
  + footerHeight;

export const LINE_MARGIN = 35;
export const ITEM_RADIUS = 21;

export const getFooterHeight = (increased: boolean) => increased ? 117 : 105;

export const INIT_Y = 190;
export const INIT_X = 210;
export const DELTA_Y = {
  top: 95,
  down: 60
};

export const PAGE_NUMBER_ATTR = 'pagenumber';
