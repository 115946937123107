import { StoreState } from 'types';

import { getMapLayers } from './map-layers';
import { getGOProperties } from './go-properties';

export function getDefaultState(state?: StoreState): StoreState {
  return state !== undefined ? state : {
    actions: {
      droneSelect: false,
      mapLayers: false,
      measure: false,
      print: false,
      crs: 'EPSG:4326'
    },
    auth: {
      kortforsyningen: null,
      loginResponse: null,
      user: null,
    },
    drone: {
      analysis: [],
      selection: null,
    },
    go: {
      properties: getGOProperties(),
    },
    loading: {
      locales: true,
      loggingIn: false,
      samlRequest: false,
      state: {
        error: null,
        isLoading: true,
        pastDelay: false,
        timedOut: false,
      },
    },
    map: {
      layers: getMapLayers(),
      measurement: [],
      search: {
        polygon: null,
        lastClickLocation: null,
        state: null,
      },
      selected: null,
      trace: null,
    },
    messages: [],
    print: {
      loading: false,
    },
    search: null,
  };
}
