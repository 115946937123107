import { Specification } from 'gridtools/types/go/telco/output';
import { formatNumber, DataItem } from '../CablePull';
import { ApiHelper, IdentificationBuilder } from './utils';
import { _LocatedIn, _Node, NodeDetails } from './types';


export const loadData = async (id: string, token: string) => {
  const api = new ApiHelper(token);

  const { root: { located_in, details } } = await api.fetchParentStructure(id);

  const portBuilder = new IdentificationBuilder();
  const fiberBuilder = new IdentificationBuilder();
  const lengths: number[] = [];

  const items: _Node[] = [];
  (located_in || []).forEach(parent => {
    const { type, located_in, sequence } = parent;

    switch (type) {
      case 'telco_fiber_port': {
        portBuilder.append(sequence, parent.details.identification);

        const details = located_in ? getFiberPortDetails(located_in, sequence, portBuilder) : null;
        api.startFetchingAddress(details);
        items[sequence] = { type, sequence, details };
        break;
      }

      case 'telco_fiber_node': {
        const details = located_in ? getFiberNodeDetails(located_in) : null;
        api.startFetchingAddress(details);
        items[sequence] = { type, sequence, details };
        break;
      }

      case 'telco_fiber': {
        fiberBuilder.append(sequence, parent.details.identification);
        lengths[sequence] = 0;

        const details = getFiberDetails(located_in, sequence, fiberBuilder, lengths);
        items[sequence] = { type, sequence, details };
        break;
      }
    }
  });

  await api.getPendingRequests();

  items.sort((n1, n2) => n1.sequence - n2.sequence);

  return {
    details,
    length: lengths.reduce((p, x) => p + x, 0),
    items
  };
};

const getFiberPortDetails = (located_in: _LocatedIn,
                             sequence: number, portIds: IdentificationBuilder,
                             rack = '', card = ''): NodeDetails | null => {

  for(const p of located_in || []) {
    const port = portIds.build(sequence);

    switch (p.type) {

      case 'telco_customer' as any: {
        const customer = p.details as any;
        return {
          name: customer.identification,
          subName: 'Kunde',
          description: customer.address_description
        };
      }

      case 'telco_container':
        return {
          name: `${p.details.type_source}: ${p.details.identification}`,
          subName: p.details.specification || '',
          description: p.details.type === 'HUB' ? `${rack} / ${card}` : '',
          port,

          type: p.type,
          id_source: p.id_source
        };

      case 'telco_route_node':
        return {
          port,
          type: p.type,
          id_source: p.id_source
        };

      case 'telco_rack_equipment': {
        if (p.details.type === 'Card') {
          card = p.details.identification || '';
        } else if (p.details.type === 'Rack') {
          rack = p.details.identification || '';
        }
        break;
      }
    }

    if (p.located_in) {
      return getFiberPortDetails(p.located_in as any, sequence, portIds, rack, card);
    }
  }

  return null;
};

const getFiberNodeDetails = (located_in: _LocatedIn,
                             result: NodeDetails = {}): NodeDetails => {

  const getText = (spec: Specification) => spec.specification
    ? `${spec.identification} (${spec.specification})`
    : `${spec.identification}`;

  for(const p of located_in || []) {
    switch (p.type) {
      case 'telco_optical_splice':
        result.subName = getText(p.details);

        if (p.located_in)
          result = getFiberNodeDetails(p.located_in as any, result);

        break;

      case 'telco_container':
        result = {
          ...result,
          name: `${p.details.type_source}: ${getText(p.details)}`,
          id_source: p.id_source,
          type: p.type
        };
        break;

      default:
        if ('located_in' in p)
          return getFiberNodeDetails(p.located_in as any, result);
    }
  }

  return result;
};

const getFiberDetails = (located_in: _LocatedIn,
                         sequence: number,
                         fiberIds: IdentificationBuilder,
                         lengths: number[]): { above: string, below: string } => {

  const result = {
    above: '',
    below: ''
  };

  for(const p of located_in || []) {
    if (p.type === 'telco_fiber_cable') {
      const len = p.details.geographical_length_m;
      result.below = `${p.details.identification} (${formatNumber(len)} m)`;
      lengths[sequence] = (lengths[sequence] || 0) + len;

    } else if ('located_in' in p) {
      return getFiberDetails(p.located_in as any, sequence, fiberIds, lengths);
    }
  }

  result.above = fiberIds.build(sequence);
  return result;
};

export const toReportItem = (node: _Node, i: number, list: _Node[]): DataItem | null => {

  if (node.type === 'telco_fiber')
    return null;

  const { details, type } = node;

  const label = details ? [
    details.name,
    details.subName,
    details.description,
    details.port,
    details.address
  ].filter(s => s) : [];

  if (i === list.length - 1) {
    return type === 'telco_fiber_port' ? { label } : null;
  }

  const next = list[i + 1];

  if (type === 'telco_fiber_port') {
    const next2 = list[i + 2];
    if (next && next2) {
      if (next.type === 'telco_fiber_node' && next2.type === 'telco_fiber') {
        return {
          ...next2.details,
          label
        };
      }
      if (next.type === 'telco_fiber_port') {
        return {
          label,
          above: 'PATCH',
          style: 'dashed'
        };
      }
    }
    return { label };
  }

  if (type === 'telco_fiber_node') {
    const prev = list[i - 1];
    if (prev && prev.type === 'telco_fiber_node'
      && next && next.type === 'telco_fiber') {

      return {
        ...next.details,
        label
      };
    }
  }

  return null;
};
