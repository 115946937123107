import { PrintState } from 'types';

const START_LOADING = 'reducers/print/start-loading';
const STOP_LOADING = 'reducers/print/stop-loading';

export function startLoading() {
  return {
    type: START_LOADING as typeof START_LOADING,
  };
}

export function stopLoading() {
  return {
    type: STOP_LOADING as typeof STOP_LOADING,
  };
}

type StartLoadingAction = ReturnType<typeof startLoading>;
type StopLoadingAction = ReturnType<typeof stopLoading>;

export type PrintAction =
  | StartLoadingAction
  | StopLoadingAction;

function startLoadingReducer(state: PrintState, action: StartLoadingAction): PrintState {
  return {
    loading: true,
  };
}

function stopLoadingReducer(state: PrintState, action: StopLoadingAction): PrintState {
  return {
    loading: false,
  };
}

export function printReducer(state: PrintState, action: PrintAction): PrintState {
  switch (action.type) {
    case START_LOADING: return startLoadingReducer(state, action);
    case STOP_LOADING: return stopLoadingReducer(state, action);
    default: return state;
  }
}
