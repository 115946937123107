import axios from 'axios';
import { geojson } from 'gridtools/types';
import { getBBox } from 'gridtools/utils/geojson';
import { DroneAnalysis } from 'types';

export function analyzeAPI(polygon: geojson.Polygon) {
  const bbox = getBBox(polygon);
  const url = API_URLS.drone_watch;
  const headers = { 'Content-Type': 'application/json' };
  return axios.post<DroneAnalysis>(url, { bbox }, { headers });
}
