import { Action, ActionsState } from 'types';

type DisableAction = ReturnType<typeof disable>;
type EnableAction = ReturnType<typeof enable>;

type SelectCrsAction = ReturnType<typeof selectCrs>;

export type ActionsAction =
  | DisableAction
  | EnableAction
  | SelectCrsAction;

const DISABLE = 'store/ui/actions/disable';
const ENABLE = 'store/ui/actions/enable';
const SELECT_CRS = 'store/ui/actions/select_crs';

export function disable(action: Action) {
  return { action, type: DISABLE as typeof DISABLE };
}

export function enable(action: Action) {
  return { action, type: ENABLE as typeof ENABLE };
}

export function selectCrs(crs: string) {
  return { type: SELECT_CRS as typeof SELECT_CRS, crs };
}

function disableReducer(state: ActionsState, action: DisableAction): ActionsState {
  switch (action.action) {
    case Action.DroneSelect: return { ...state, droneSelect: false };
    case Action.MapLayers: return { ...state, mapLayers: false };
    case Action.Measure: return { ...state, measure: false };
    case Action.Print: return { ...state, print: false };
  }
}

function enableReducer(state: ActionsState, action: EnableAction): ActionsState {
  switch (action.action) {
    case Action.DroneSelect: return { ...state, droneSelect: true, measure: false, print: false };
    case Action.MapLayers: return { ...state, mapLayers: true };
    case Action.Measure: return { ...state, droneSelect: false, measure: true, print: false };
    case Action.Print: return { ...state, print: true };
  }
}

function selectCrsReducer(state: ActionsState, { crs }: SelectCrsAction): ActionsState {
  return { ...state, crs };
}

export function actionsReducer(state: ActionsState, action: ActionsAction): ActionsState {
  switch (action.type) {
    case DISABLE: return disableReducer(state, action);
    case ENABLE: return enableReducer(state, action);
    case SELECT_CRS: return selectCrsReducer(state, action);
    default: return state;
  }
}
