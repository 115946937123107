function isLocalStorageAvailable() {
  // https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
  try {
    const x = '__storage_test__';
    localStorage.setItem(x, x);
    localStorage.removeItem(x);
    return true;
  } catch (exc) {
    return (
         exc instanceof DOMException
      && (
           // everything except Firefox
           exc.code === 22
           // Firefox
        || exc.code === 1014
           // test name field too, because code might not be present
           // everything except Firefox
        || exc.name === 'QuotaExceededError'
           // Firefox
        || exc.name === 'NS_ERROR_DOM_QUOTA_REACHED'
      )
      // acknowledge QuotaExceededError only if there's something already stored
      && localStorage.length !== 0
    );
  }
}

function setCookie(name: string, value: string, days: number) {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  document.cookie = `${name}=${value};path=/;expires=${date.toUTCString()}`;
}

const USE_LOCAL_STORAGE = isLocalStorageAvailable();

export function getItem(key: string): null | string {
  if (USE_LOCAL_STORAGE) {
    return localStorage.getItem(key);
  } else {
    const expression = `(^|;) ?${key}=([^;]*)(;|$)`;
    const matches = document.cookie.match(expression);
    return matches === null ? null : matches[2];
  }
}

export function getJson<Data extends object = object>(key: string): null | Data {
  const value = getItem(key);
  return value === null ? null : JSON.parse(value) as Data;
}

export function removeItem(key: string): void {
  if (USE_LOCAL_STORAGE) {
    localStorage.removeItem(key);
  } else {
    setCookie(key, '', -1);
  }
}

export function setItem(key: string, value: string): void {
  if (USE_LOCAL_STORAGE) {
    localStorage.setItem(key, value);
  } else {
    setCookie(key, value, 10 * 365);
  }
}

export function setJson<Data extends object = object>(key: string, obj: Data): void {
  const value = JSON.stringify(obj);
  setItem(key, value);
}
