import { DroneAnalysis, DroneAnalysisState } from 'types';

const ADD = 'store/drone/analysis/add';

type AddAction = ReturnType<typeof add>;

export type DroneAnalysisAction =
  | AddAction;

export function add(analysis: DroneAnalysis) {
  return { analysis, type: ADD as typeof ADD };
}

function _add(state: DroneAnalysisState, action: AddAction): DroneAnalysisState {
  return [...state, action.analysis];
}

export function droneAnalysisReducer(state: DroneAnalysisState, action: DroneAnalysisAction): DroneAnalysisState {
  switch (action.type) {
    case ADD: return _add(state, action);
    default: return state;
  }
}
