import { configureRoot } from 'app/components/Root';
import { configureStore } from 'app/store/configure';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import { reports } from 'app/components/Routes';
import proj from 'ol/proj';

// override react-router Route propTypes
// TODO: remove this when react-router allows an array of strings in route path
async function overrideRoutePropTypes() {
  const PropTypes = await import('prop-types');
  (Route as any).propTypes = {
    children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
    component: PropTypes.func,
    computedMatch: PropTypes.object,
    exact: PropTypes.bool,
    location: PropTypes.object,
    path: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
    render: PropTypes.func,
    sensitive: PropTypes.bool,
    strict: PropTypes.bool,
  };
}

if (IS_DEVELOPMENT) {
  overrideRoutePropTypes();
}

function prepareProj() {
  const proj4 = require('proj4');
  proj4.defs("EPSG:25832","+proj=utm +zone=32 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");
  proj.setProj4(proj4);
}

// setup app
async function setUp() {
  prepareProj();

  const Root = await configureRoot();
  const store = await configureStore();

  const ProvidedRoot = () => (
    <Provider store={store}>
      <Root />
    </Provider>
  );

  ReactDOM.render(
    <Router>
      <Switch>
        {reports}
        <Route component={ProvidedRoot}/>
      </Switch>
    </Router>,
    document.getElementById('app'));
}

setUp();
