import { timeFormat, formatLocale, sum } from 'd3';
import { getFooterHeight, getReportHeight, MIN_HEIGHT_MM, STYLES, WIDTH_MM, WIDTH_PX } from './constants';
import { _AddressBlock, DataItem, FiberCableStructure, Unit } from './types';
import { _LineInfo, findConduitContainer, getInfo, getLineInfo, isRouteNode } from './telco';

export const formatDate = timeFormat('%Y-%m-%d %H:%M:%S');
const formatDateForFile = timeFormat('%Y-%m-%d %H%M%S');

const customLocale = formatLocale({
  thousands: '.',
  grouping: [3]
} as any);
export const formatNumber = customLocale.format(',d');

export const getTotalLength = (items: Array<{ length?: number }>) =>
  sum(items, i => i.length || 0);

export const guardSwitch = <T>(tested: never, message: string, fallbackValue: T) => {
  //console.error(message + ' ' + tested);
  return fallbackValue;
};

const coeffToMm = (unit: Unit) => {
  const inch = 25.4;
  switch (unit) {
    case 'mm': return 1;
    case 'px': return WIDTH_MM / WIDTH_PX;
    case 'pt': return inch / 72;
    case 'in': return inch;

    default: return guardSwitch(unit, 'unknown unit', 1);
  }
};
export const convertUnit = (value: number, unit: Unit, target: Unit) => {
  const coeff = unit === target ? 1
    : coeffToMm(unit) / coeffToMm(target);
  return coeff * value;
};

export const measureText = (text: string, font: string | number) => {

  if (typeof font === 'number')
    font = `normal ${font}px ${STYLES.svg.fontFamily}`;

  const mt = measureText as { canvas?: HTMLCanvasElement };
  const canvas = mt.canvas || (mt.canvas = document.createElement('canvas'));

  const context = canvas.getContext('2d') as CanvasRenderingContext2D;
  context.font = font;

  const metrics = context.measureText(text);
  return metrics.width;
};

// simple word-wrap text algorithm
//  splits the text into words (by spaces),
//  then builds lines: adds words while the line fits to maxWidth
export const wrapText = (text: string, fontSize: number, maxWidth: number) => {
  const measure = (s: string) => measureText(s, fontSize);

  if (measure(text) <= maxWidth) {
    return [ text ];
  }

  const words = text.split(' ');
  const lines = [] as string[];
  let current = '';
  for(const w of words) {
    const withNextWord = `${current} ${w}`.trim(),
      width = measure(withNextWord);
    if (width > maxWidth) {
      lines.push(current);
      current = w;
    } else {
      current = withNextWord;
    }
  }

  return lines.concat(current).filter(s => s);
};

export const calcReportDimensions = (itemsCount: number,
                                     address: _AddressBlock[]) => {
  const footerHeight = getFooterHeight(
    address.some(addr => !!addr.line1 && !!addr.line2));

  const rows = Math.ceil(itemsCount / 3);
  const height = Math.max(
    convertUnit(getReportHeight(rows, footerHeight), 'px', 'mm'),
    MIN_HEIGHT_MM
  );

  const pxSizes = {
    width: WIDTH_PX,
    height: convertUnit(height, 'mm', 'px'),

    footerHeight
  };

  return {
    mm: {
      width: WIDTH_MM + 'mm',
      height: height + 'mm'
    },

    px: pxSizes,

    viewBox: `0 0 ${pxSizes.width} ${pxSizes.height}`
  };
};

export const buildFileName = (type: string, version: string | null) => {
  type = type.replace(/æ/g, 'ae').replace(/ø/g, 'o');
  version = version || 'Engineering Design';
  const date = formatDateForFile(new Date());

  return `Splidseplan ${type} (${version}) ${date}.pdf`;
};

export const buildPageNumber = (page: number, totalPages: number) =>
  `${page} OF ${totalPages}`;

export const prepareStruct = (struct: FiberCableStructure) => {

  type Node = {
    item: ReturnType<typeof getInfo>,
    line?: _LineInfo,
    isRouteNode?: boolean,
    isRoute?: boolean,
    collapsed?: boolean,

    nextObj?: Node,

    type?: DataItem['type']
  };
  const nodes = struct.objects.map((o, i): Node => {
    const item = getInfo(o);

    const last = i === struct.objects.length - 1;
    const strLine = !last && struct.lines[i];
    const line = strLine ? getLineInfo(strLine) : undefined;

    const cont = strLine && findConduitContainer(strLine);

    const itemType: Node['type'] = item && item.isContainerInRouteNode ? 'rn_container' : 'item';

    if (cont) {
      return {
        item,
        nextObj: {
          item: getInfo(cont as any),
          line,
          type: 'passthrough'
        },
        type: itemType
      };
    }

    return {
      item,
      line,
      isRouteNode: isRouteNode(o),
      isRoute: line && line.isRoute,
      type: itemType,
    };
  });

  for(let i = nodes.length - 1; i >= 0; i--) {
    const nextObj = nodes[i].nextObj;
    if (nextObj)
      nodes.splice(i + 1, 0, nextObj);
  }

  for(let i = nodes.length - 2; i >= 1; i--) {
    const n = nodes[i]
      , prev = nodes[i - 1];

    const routes = n.isRouteNode && n.isRoute && prev.isRoute;
    if (routes && n.type !== 'rn_container') { // rn->container nodes disable merging
      n.collapsed = true;

      // append length to prev line
      if (n.line && prev.line) {
        n.line.length += prev.line.length;
        n.line.centerline.push(...prev.line.centerline);
        n.line.objects.push(...prev.line.objects);
      }
      prev.line = n.line;
    }
  }

  return nodes
    .filter(n => !n.collapsed)
    .map(n => ({
      ...n.line as _LineInfo,
      label: n.item && [n.item.label, n.item.spec, n.item.addr],
      type: n.type || 'item',
      location: n.item && n.item.location,

      objects: {
        item: n.item ? n.item.objects : [],
        line: n.line ? n.line.objects : []
      },

      address: n.item ? { value: n.item.addr, id: n.item.addressId } : undefined
    }));
};
