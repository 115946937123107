import { utils } from 'gridtools/types';
import * as React from 'react';
import { Language } from 'types';

import { tracker } from './tracker';
import { _State, InjectedAnalyticsProps } from './types';

export function analytics<WrappedProps extends InjectedAnalyticsProps>(
  WrappedComponent: React.ComponentType<WrappedProps>
): React.ComponentType<utils.ExcludeKeys<WrappedProps, 'analytics'>> {
  class Analytics extends React.PureComponent<WrappedProps, _State> {
    static displayName = `Analytics(${WrappedComponent.displayName})`;

    constructor(props: WrappedProps) {
      super(props);
      this.state = {
        analytics: {
          pageView: this.pageView,
          setLanguage: this.setLanguage,
          setUser: this.setUser,
        },
      };
    }

    pageView = (options: (url: string) => { page: string; title: string; }) => {
      const url = window.location.pathname;
      const { page, title } = options(url);
      tracker.pageView(page, title);
    }

    setLanguage = (language: Language) => {
      tracker.setLanguage(language);
    }

    setUser = (id: string | number) => {
      tracker.setUser(id);
    }

    render() {
      return <WrappedComponent {...this.props} analytics={this.state.analytics} />;
    }
  }

  return Analytics as any;
}
